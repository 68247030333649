.photo-album {
  color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2vw;
}
.photo-section {
  display: flex;
  align-items: center;
}
.prod-photo {
  margin: 2vw;
  max-width: 20vw;
  max-height: 20vw;
  /* min-width: 16w;
  min-height: 16vw; */
}
.focus-photo-modal {
  width: 100vw;
  height: 100vh;
  background-color: white;
  position: fixed;
  opacity: 0.5;
  display: flex;
  /* align-items: center; */
  top: 0;
}
.prod-photo-hidden {
  visibility: hidden;
}
.prod-photo-visible {
  visibility: visible;
}
/* .focused-photo-and-arrows {
  position: absolute;
  display: flex;
} */
.focused-photo {
  position: fixed;
  max-height: 80vh;
  max-width: 80vw;
  /* min-height: 40%;
  min-width: 40%; */
  border: 1vw solid rgb(64, 3, 90);
  /* top: 20%; */
  line-height: 100vh;
  align-self: center;
}
.arrow {
  position: fixed;
  text-align: center;
  justify-content: right;
  color: white;
  background-color: rgb(64, 3, 90);
  width: 8vw;
  padding: 1vw;
  top: 45vh;
}
.arrow:disabled {
  background-color: grey;
}
.arrow-left {
  left: 0;
}
.arrow-right {
  right: 0;
}
.gallery {
  display: flex;
  flex-wrap: wrap;
  width: 96vw;
  justify-content: space-evenly;
}
