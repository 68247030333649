.home-nav-button-link {
  text-decoration: none;
  color: rgb(230, 180, 88);
  font-size: 1.5vw;
}
.home-nav-button {
  background: rgb(82, 54, 92);
  /* background-color: rgb(172, 112, 2); */
  border: 2px solid rgb(78, 5, 78);
  box-shadow: -5px 5px 10px black;
  font-family: cursive;
  text-shadow: 1px 1px #000000;
  border-radius: 7px;
  width: 25vw;
  height: 9vh;
  line-height: 9vh;
  text-decoration: none;
  font-weight: 700;
  margin: 2.5vh;
  font-size: 3.25vh;
  /* transition-timing-function: all linear; */
  transition: all 0.5s;
  font-family: 'Great Vibes';
}
.home-nav-button:hover {
  box-shadow: -8px 18px 15px black;
  transform: scale(1.075);
  transition: all 0.5s linear;
  color: rgb(82, 54, 92);
  background-color: rgb(230, 180, 88);
}
