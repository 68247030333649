.bio-div {
  color: white;
  display: flex;
  align-items: center;
  /* height: 85vh; */
  justify-content: space-evenly;
  padding: 1vw;
  /* margin: 2vw 2vw 0 2vw; */
  /* background-color: rgba(0, 0, 0, 0.35); */
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.35));
  height: 110%;
}
.bio-pic {
  width: 30vw;
  margin-left: 3.5vw;
  margin-right: -1vw;
  box-shadow: 10px -10px 10px grey;
}
.bio-container {
  /* height: 70vh; */
  width: 80vw;
  /* border-radius: 25%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1vw 1.5vw;
  color: rgb(245, 235, 224);
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 1.25vw;
  font-weight: 300;
  line-height: 2vw;
}
.bio-container p {
  /* margin-bottom: 0; */
  width: 80%;
}
.bio-hi {
  color: rgb(248, 210, 139);
  font-size: 1.25em;
}
.signature {
  width: 8vw;
  /* width: 15vw; */
  opacity: 0.25;
}


@media (max-width: 950px) {
  .bio-div {
    display: block;
  }
  .bio-pic {
    margin: 5vw 0 2vw 2vw;
  }
  .bio-container {
    text-align: center;
    width: 100%;
    color: rgb(245, 235, 224);
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 3vw;
    font-weight: 300;
    line-height: 5vw;
  }
  .bio-container p {
    /* margin-bottom: 0; */
    width: 80%;
  }
  .signature {
    width: 16vw;
    opacity: 0.25;
  }
}
