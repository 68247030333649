.resume-div {
  display: flex;
  /* height: 140vh; */
  align-items: center;
  justify-content: center;
  padding: 6vw;
}

.resume {
  /* height: 100%; */
  width: 65vw;
}

@media (max-width: 950px) {
  .resume {
    width: 85vw;
  }
}
