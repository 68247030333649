.home-div {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: white;
  height: 85vh;
}
.headshot-container {
  /* height: 85vh; */
  display: flex;
  /* height: ; */
}
.headshot {
  height: 57.25vh;
}
