.banner {
  margin: 0;
  /* padding-bottom: -4vw; */
  /* padding-top: 4vw; */
  width: 100%;
  /* height: 13vh; */
  height: 5vw;
  /* max-height: 15vh; */
  /* vertical-align: baseline; */
  /* line-height: 100%; */
  /* background-color: rgb(0, 0, 0); */
  background: linear-gradient(135deg, rgb(64, 3, 90) 50%, black);
  /* font-size: 7.5vh; */
  color: rgb(124, 116, 124);
  text-shadow: 3px 3px black;
  /* text-align: left; */
  font-family: 'Pinyon Script', cursive;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  /* padding-top: 1vw; */
  /* margin-bottom: -1.25vw; */
}
.banner-name {
  /* height: 15vh;
  max-height: 15vh; */
  line-height: 3.5vw;
  margin: 0;
  margin-left: 2vw;
  display: inline;
  /* vertical-align: bottom; */
  font-size: 5vw;
  /* align-self: flex-end; */
  text-shadow: 5px 5px 10px black;
  /* margin-bottom: 0.vw; */
}
.banner-nav {
  display: inline-flex;
  justify-content: space-evenly;
  margin-right: 1.5vw;
  font-family: 'Great Vibes';
  line-height: 0.5vw;
  /* align-self: flex-end; */
  /* margin-bottom: -0.75vw; */
}
@font-face {
  font-family: 'Pinyon Script';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/Pinyon_Script/PinyonScript-Regular.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Great Vibes';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/Great_Vibes/GreatVibes-Regular.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
