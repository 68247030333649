.banner-nav-btn-link {
  text-decoration: none;
  color: rgb(230, 180, 88);
  /* text-shadow: 3px 3px #000000; */
  font-size: 2.25vw;
  /* box-shadow: 0px 0px 55px gold; */
}
.banner-nav-btn {
  /* height: 15vh; */
  margin: 1vw;
  /* transition: all 3s; */
  /* animation: fadeIn 1s ease; */
  /* transition: all 3s ease-in-out; */
  /*-webkit-animation: fadeIn 2s; /* Safari, Chrome and Opera > 12.1 */
  /* -moz-animation: fadeIn 2s; Firefox < 16 */
  /* -ms-animation: fadeIn 2s; Internet Explorer */
  /* -o-animation: fadeIn 2s; Opera < 12.1 */
  /* animation: fadeIn 2s; */
  /* animation-duration: 3s;
  animation-name: fadeIn; */
  /* transition: opacity 5s;
  opacity: 1; */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.highlight {
  /* text-shadow: 5px 5px 5px orange; */
  color: rgb(252, 228, 178);
}
